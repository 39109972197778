var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('BModal', {
    attrs: {
      "id": "modal-recalculate-show-price",
      "title": _vm.$t('reservation.showCalculatePrice.title'),
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "centered": "",
      "no-close-on-backdrop": "",
      "size": "lg"
    },
    on: {
      "hide": _vm.handleHideModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex-between w-100"
        }, [_c('div', {
          staticClass: "text-danger text-20px"
        }, [_vm.conversionTimeout ? _c('span', [_vm._v(" Hết hạn sau: "), _c('span', {
          staticClass: "fw-700"
        }, [_vm._v(_vm._s(_vm.conversionTimeout / 1000) + "s")])]) : _vm._e()]), _c('div', {
          staticClass: "d-flex gap-2"
        }, [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "center rounded-pill width-100",
          attrs: {
            "size": "md",
            "variant": "outline-secondary"
          },
          on: {
            "click": _vm.handleHideModal
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.close')) + " ")]), _c('b-button', {
          staticClass: "btn-gradient border-0",
          attrs: {
            "pill": ""
          },
          on: {
            "click": _vm.submitSavePrice
          }
        }, [_c('span', {
          staticClass: "align-middle"
        }, [_vm._v(_vm._s(_vm.$t('reservation.showCalculatePrice.savePrice')))])])], 1)])];
      },
      proxy: true
    }])
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading
    }
  }, [!_vm.isEmpty(_vm.newPrice) && !_vm.isEmpty(_vm.getBookingData) ? _c('div', {
    staticClass: "mb-1 ml-1"
  }, [['1S', '1S_CTRL'].includes(_vm.getBookingData.source) && _vm.isEmpty(_vm.newPrice[0].headerInformation) ? _c('div', [_c('div', {
    staticClass: "font-medium-2 font-weight-bold mb-50 mt-1"
  }, [_vm._v(" Thông tin hành trình hiện tại ")]), _vm._l(_vm.getBookingData.itineraries.flat().filter(function (item) {
    return _vm.newPrice[0].bookingInfos.find(function (i) {
      return i.segmentId === item.segmentId;
    });
  }), function (trip, tripIndex1) {
    return _c('b-card', {
      key: "1S-empty-".concat(tripIndex1),
      staticClass: "text-wrap mb-50",
      attrs: {
        "body-class": "p-50"
      }
    }, [_c('span', {
      staticClass: "font-weight-bolder font-medium-3 pl-50 text-dark"
    }, [_vm._v(" " + _vm._s(_vm.convertShortTrip(trip)) + " ")])]);
  })], 2) : _vm.getBookingData.source === '1G' && _vm.isEmpty(_vm.newPrice[0].headerInformation) ? _c('div', [_c('div', {
    staticClass: "font-medium-2 font-weight-bold mb-50 mt-1"
  }, [_vm._v(" Thông tin hành trình hiện tại ")]), _vm._l(_vm.newPrice[0].bookingInfos, function (trip, tripIndex2) {
    return _c('b-card', {
      key: "1G-".concat(tripIndex2),
      staticClass: "text-nowrap mb-50",
      attrs: {
        "body-class": "p-50"
      }
    }, [_c('span', {
      staticClass: "font-weight-bolder font-medium-3 pl-50 text-dark"
    }, [_vm._v(" " + _vm._s(_vm.getShortenTrip1G(trip)) + " ")])]);
  })], 2) : _vm.newPrice[0].headerInformation && _vm.newPrice[0].headerInformation.length > 0 ? _c('div', [_c('div', {
    staticClass: "font-medium-2 font-weight-bold mb-50 mt-1"
  }, [_vm._v(" Thông tin hành trình hiện tại ")]), _vm._l(_vm.getBookingData.itineraries.flat().filter(function (item) {
    return _vm.newPrice[0].bookingInfos.find(function (i) {
      return i.segmentId === item.segmentId;
    });
  }), function (trip, tripIndex3) {
    return _c('b-card', {
      key: "else-".concat(tripIndex3),
      staticClass: "text-wrap mb-50",
      attrs: {
        "body-class": "p-50"
      }
    }, [_c('span', {
      staticClass: "font-weight-bolder font-medium-3 pl-50 text-dark"
    }, [_vm._v(" " + _vm._s(_vm.convertShortTrip(trip)) + " ")])]);
  }), _c('b-alert', {
    staticClass: "px-1 py-50 my-50",
    attrs: {
      "variant": "danger",
      "show": ""
    }
  }, [_vm._v(" GIÁ VÉ CÓ SỰ THAY ĐỔI HẠNG ĐẶT CHỖ, VUI LÒNG KIỂM TRA! ")]), _c('div', {
    staticClass: "font-medium-2 font-italic font-weight-bolder mb-25 mt-1 text-airline"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.showCalculatePrice.headerInformationTitle')) + " ")]), _vm._l(_vm.newPrice[0].headerInformation, function (newTrip, newTripIndex) {
    return _c('b-card', {
      key: "newtrip-".concat(newTripIndex),
      staticClass: "text-nowrap mb-50",
      attrs: {
        "body-class": "p-50"
      }
    }, [_c('span', {
      staticClass: "font-weight-bolder font-medium-3 pl-50 text-airline text-wrap"
    }, [_vm._v(" " + _vm._s(newTrip) + " ")])]);
  })], 2) : _vm._e()]) : _vm._e(), _c('BTableLite', {
    staticClass: "mb-1 rounded",
    attrs: {
      "bordered": "",
      "responsive": "",
      "thead-class": "text-dark font-weight-bolder text-nowrap",
      "fields": _vm.tableColumns,
      "items": _vm.newPrice
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumns, function (column) {
      return {
        key: "head(".concat(column, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: column,
            staticClass: "text-dark"
          }, [_vm._v(" " + _vm._s(_vm.$t("reservation.showCalculatePrice.columns.".concat(data.column))) + " ")])];
        }
      };
    }), {
      key: "cell(appliedPassengers)",
      fn: function fn(data) {
        return _vm._l(_vm.getBookingData.paxLists.filter(function (item) {
          return data.item.appliedPassengers.find(function (i) {
            return i.paxId === item.paxId;
          });
        }), function (passenger, passengerIndex) {
          return _c('div', {
            key: "paspassenger-".concat(passengerIndex),
            staticClass: "text-nowrap"
          }, [_c('span', [passenger.paxId && passenger.paxId.length <= 5 ? _c('small', {
            staticClass: "text-warning"
          }, [_vm._v(" " + _vm._s(passenger.paxId) + " ")]) : _vm._e(), _vm._v(" " + _vm._s("".concat(passenger.lastName, " ").concat(passenger.firstName)) + " ")])]);
        });
      }
    }, {
      key: "cell(basePrice)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(data.item.basePrice * data.item.appliedPassengers.length)) + " ")])];
      }
    }, {
      key: "cell(totalTaxes)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(data.item.totalTaxes * data.item.appliedPassengers.length)) + " ")])];
      }
    }, {
      key: "cell(totalPrice)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-right font-weight-bolder"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(data.item.totalPrice * data.item.appliedPassengers.length)) + " ")])];
      }
    }], null, true)
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }