<template>
  <div>
    <!-- SECTION modal-recalculate-show-price  -->
    <BModal
      id="modal-recalculate-show-price"
      :title="$t('reservation.showCalculatePrice.title')"
      title-class="text-airline font-medium-4 font-weight-bolder"
      centered
      no-close-on-backdrop
      size="lg"
      @hide="handleHideModal"
    >
      <IAmOverlay :loading="loading">
        <div
          v-if="!isEmpty(newPrice) && !isEmpty(getBookingData)"
          class="mb-1 ml-1"
        >
          <div v-if="['1S', '1S_CTRL'].includes(getBookingData.source) && isEmpty(newPrice[0].headerInformation)">
            <div class="font-medium-2 font-weight-bold mb-50 mt-1">
              Thông tin hành trình hiện tại
            </div>
            <b-card
              v-for="(trip, tripIndex1) in getBookingData.itineraries.flat().filter(item => newPrice[0].bookingInfos.find(i => i.segmentId === item.segmentId))"
              :key="`1S-empty-${tripIndex1}`"
              class="text-wrap mb-50"
              body-class="p-50"
            >
              <span class="font-weight-bolder font-medium-3 pl-50 text-dark">
                {{ convertShortTrip(trip) }}
              </span>
            </b-card>
          </div>

          <div v-else-if="getBookingData.source === '1G' && isEmpty(newPrice[0].headerInformation)">
            <div class="font-medium-2 font-weight-bold mb-50 mt-1">
              Thông tin hành trình hiện tại
            </div>
            <b-card
              v-for="(trip, tripIndex2) in newPrice[0].bookingInfos"
              :key="`1G-${tripIndex2}`"
              class="text-nowrap mb-50"
              body-class="p-50"
            >
              <span class="font-weight-bolder font-medium-3 pl-50 text-dark">
                {{ getShortenTrip1G(trip) }}
              </span>
            </b-card>
          </div>

          <div v-else-if="newPrice[0].headerInformation && newPrice[0].headerInformation.length > 0">
            <div class="font-medium-2 font-weight-bold mb-50 mt-1">
              Thông tin hành trình hiện tại
            </div>
            <b-card
              v-for="(trip, tripIndex3) in getBookingData.itineraries.flat().filter(item => newPrice[0].bookingInfos.find(i => i.segmentId === item.segmentId))"
              :key="`else-${tripIndex3}`"
              class="text-wrap mb-50"
              body-class="p-50"
            >
              <span class="font-weight-bolder font-medium-3 pl-50 text-dark">
                {{ convertShortTrip(trip) }}
              </span>
            </b-card>

            <b-alert
              variant="danger"
              show
              class="px-1 py-50 my-50"
            >
              GIÁ VÉ CÓ SỰ THAY ĐỔI HẠNG ĐẶT CHỖ, VUI LÒNG KIỂM TRA!
            </b-alert>

            <div class="font-medium-2 font-italic font-weight-bolder mb-25 mt-1 text-airline">
              {{ $t('reservation.showCalculatePrice.headerInformationTitle') }}
            </div>
            <b-card
              v-for="(newTrip, newTripIndex) in newPrice[0].headerInformation"
              :key="`newtrip-${newTripIndex}`"
              class="text-nowrap mb-50"
              body-class="p-50"
            >
              <span class="font-weight-bolder font-medium-3 pl-50 text-airline text-wrap">
                {{ newTrip }}
              </span>
            </b-card>
          </div>
        </div>

        <BTableLite
          bordered
          responsive
          class="mb-1 rounded"
          thead-class="text-dark font-weight-bolder text-nowrap"
          :fields="tableColumns"
          :items="newPrice"
        >
          <template
            v-for="column in tableColumns"
            v-slot:[`head(${column})`]="data"
          >
            <span
              :key="column"
              class="text-dark"
            >
              {{ $t(`reservation.showCalculatePrice.columns.${data.column}`) }}
            </span>
          </template>

          <template #cell(appliedPassengers)="data">
            <div
              v-for="(passenger, passengerIndex) in getBookingData.paxLists.filter(item => data.item.appliedPassengers.find(i => i.paxId === item.paxId))"
              :key="`paspassenger-${passengerIndex}`"
              class="text-nowrap"
            >
              <span>
                <small
                  v-if="passenger.paxId && passenger.paxId.length <= 5"
                  class="text-warning"
                >
                  {{ passenger.paxId }}
                </small>
                {{ `${passenger.lastName} ${passenger.firstName}` }}
              </span>
            </div>
          </template>
          <template #cell(basePrice)="data">
            <div class="text-right">
              {{ formatCurrency(data.item.basePrice * data.item.appliedPassengers.length) }}
            </div>
          </template>
          <template #cell(totalTaxes)="data">
            <div class="text-right">
              {{ formatCurrency(data.item.totalTaxes * data.item.appliedPassengers.length) }}
            </div>
          </template>
          <template #cell(totalPrice)="data">
            <div class="text-right font-weight-bolder">
              {{ formatCurrency(data.item.totalPrice * data.item.appliedPassengers.length) }}
            </div>
          </template>
        </BTableLite>
      </IAmOverlay>

      <template #modal-footer>
        <div class="d-flex-between w-100">
          <div class="text-danger text-20px">
            <span v-if="conversionTimeout">
              Hết hạn sau: <span class="fw-700">{{ conversionTimeout / 1000 }}s</span>
            </span>
          </div>
          <div class="d-flex gap-2">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              size="md"
              variant="outline-secondary"
              class="center rounded-pill width-100"
              @click="handleHideModal"
            >
              {{ $t('reservation.close') }}
            </b-button>

            <b-button
              class="btn-gradient border-0"
              pill
              @click="submitSavePrice"
            >
              <span class="align-middle">{{ $t('reservation.showCalculatePrice.savePrice') }}</span>
            </b-button>
          </div>
        </div>
      </template>
    </BModal>
    <!-- !SECTION -->
  </div>
</template>

<script>
import {
  BModal, BTableLite, BButton, BAlert, BCard,
} from 'bootstrap-vue'
import isEmpty from 'lodash/isEmpty'

import IAmOverlay from '@/components/IAmOverlay.vue'

import {
  formatCurrency, convertShortTrip, convertISODateTime,
} from '@core/utils/filter'

export default {
  components: {
    BModal,
    BTableLite,
    BButton,
    BAlert,
    BCard,
    IAmOverlay,
  },
  props: {
    newPrice: {
      type: [Array],
      default: null,
    },
    getBookingData: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    conversionTimeout: {
      type: [Number, null],
      default: null,
    },
  },
  setup(props, { emit, root }) {
    const tableColumns = ['appliedPassengers', 'basePrice', 'totalTaxes', 'totalPrice']
    function submitSavePrice() {
      emit('save-price')
    }

    function handleHideModal() {
      const modalShow = Array.from(window.document.querySelectorAll('.modal.show'))
        .map(el => el.id).filter(id => id === 'modal-recalculate-show-price')

      modalShow.forEach(id => {
        root.$root.$emit('bv::hide::modal', id)
      })

      emit('cleanAirPricingConversion')
    }

    function getShortenTrip1G(trip) {
      const findTrip = props.getBookingData.itineraries.flat().find(segment => trip.airline === segment.airline
      && trip.departure === segment.departure.iataCode
      && trip.departureDate === segment.departure.at
      && trip.departureTimezone === segment.departure.timeZone
      && trip.arrival === segment.arrival.iataCode
      && trip.flightNumber === segment.flightNumber
      && trip.bookingClass === segment.bookingClass)
      return `${trip.airline}${trip.flightNumber} ${trip.bookingClass} ${convertISODateTime(trip.departureDate, trip.departureTimezone || '+07:00').dayMonth || ''} ${trip.departure}${trip.arrival} ${convertISODateTime(trip.departureDate, trip.departureTimezone || '+07:00').hourMin || ''} ${findTrip ? convertISODateTime(findTrip.arrival.at, findTrip.arrival.timeZone || '+07:00').hourMin : ''}`
    }

    return {
      convertISODateTime,
      handleHideModal,
      tableColumns,
      isEmpty,
      formatCurrency,
      convertShortTrip,
      submitSavePrice,
      getShortenTrip1G,
    }
  },
}
</script>
